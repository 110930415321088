import React from "react"
import PropTypes from "prop-types"
import { useInView } from 'react-intersection-observer'
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon62 from "../images/icon-number-62.inline.svg"
import "./_listen-sub.scss"

const LSTitle = ({ title }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '-80px 0px',
    // triggerOnce: true,
  })

  return (
    <h3 className={`title ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      <ReactMarkdown children={title} />
    </h3>
  )
}
const LSSub = ({ date1, date2 }) => {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '-80px 0px',
    // triggerOnce: true,
  })

  return (
    <div className={`subtitle ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      <span>{date1}</span>
      <span>{date2}</span>
    </div>
  )
}
const LSCopy = ({ subhead }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '-80px 0px',
    // triggerOnce: true,
  })

  return (
    <div className={`copy ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      {subhead}
    </div>
  )
}

const ListenSub = ({ title, date1, date2, subhead, quote, image, imageDesc }) => {
  const { ref, inView } = useInView({
    threshold: 0.75,
    rootMargin: '400px 0px -100px',
    // triggerOnce: true,
  })

  return (
    <div id="listen-sub">
      <div className="content-col">
        <LSTitle title={title} />
        {/* <h3 className="title">
          <ReactMarkdown children={title} />
        </h3> */}
        <LSSub date1={date1} date2={date2} />
        {/* <div className="subtitle">
          <span>{date1}</span>
          <span>{date2}</span>
        </div> */}
        {/* <div className="copy">{subhead}</div> */}
        <LSCopy subhead={subhead} />
        <div className="quote">{quote}</div>
      </div>

      <div className="image-col">
        <div className="image-icon">
          <div className={inView ? 'in-view active' : 'in-view'} ref={ref}>
            <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.alternativeText} />
          </div>
          <Icon62 />
        </div>
        <div className="image-desc">{imageDesc}</div>
      </div>
    </div>
  )
}

ListenSub.propTypes = {
  title: PropTypes.string.isRequired,
  date1: PropTypes.string.isRequired,
  date2: PropTypes.string.isRequired,
  subhead: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  imageDesc: PropTypes.string.isRequired
}

export default ListenSub
