import React from "react"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"
import { useInView } from 'react-intersection-observer'
import IconNumber from "./icon-number"
import CircleIcon from "../images/icon-circle-arrow.inline.svg"
import "./_teaser.scss"

const Teaser = ({ title, description, ctalabel, ctalink, i }) => {
  const { ref, inView } = useInView({
    rootMargin: '200px 0px -80px',
    threshold: 0.5,
    // triggerOnce: true,
  })

  const step = '0' + (i + 1)
  
  return (
    <div className={`teaser ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      <IconNumber i={i} />
      <div className="teaser-text">
        <div className="subtitle">
          <span>{title}</span>
          <span>Step {step}</span>
        </div>
        { description && description.data && description.data.description &&
        <ReactMarkdown children={description.data.description} className="copy" />
        }
        <a className="teaser-cta" href={ctalink}>
          {ctalabel}
          <CircleIcon />
        </a>
      </div>
    </div>
  )
}

Teaser.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  ctalabel: PropTypes.string.isRequired,
  ctalink: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired
}

export default Teaser
