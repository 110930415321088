import React, { useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer'
import IconClose from "../images/icon-close.inline.svg"
import jfkVideoSrc from "../JFK_Rice_University_Speech.mp4"
import "./_jfk-video.scss"

const JFKSpeech = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '400px 0px -100px',
  })

  const [isOpen, setIsOpen] = useState(false)
  // const modalRef = useRef(null)
  const videoRef = useRef(null)
  const handleCloseModal = () => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('no-scroll')
    }
    setIsOpen(false)
    videoRef.current.pause()
  }
  const handleOpenModal = () => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('no-scroll')
    }
    setIsOpen(true)
    videoRef.current.play()
  }

  return (
    <div id="jfk-video">
      <div className={`jfk-speech-content ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
        <div className="jfk-speech-video">
          <StaticImage src="../jfk-video-poster.jpg" alt="JFK Video Poster" height={480} />
          <button onClick={handleOpenModal} >
            <StaticImage src="../images/icon-play.png" alt="Play" objectFit="contain" placeholder={"none"} />
            <div>Play Video</div>
          </button>
        </div>
        <div className="jfk-speech-tippy">
          <div>
            <span className="tippy-top">Hear him speak</span>
            <span className="tippy-pot">Play&nbsp;the&nbsp;1963 moonshot&nbsp;speech</span>
          </div>
        </div>
      </div>

      <div className={`${isOpen ? 'jfk-video-modal open' : 'jfk-video-modal'}`}>
        <button onClick={handleCloseModal}>
          <IconClose />
        </button>
        <video ref={videoRef} width="640" height="480" controls={true}>
          <source src={jfkVideoSrc} type="video/mp4" />
          <track default kind="captions" srcLang="en" />
          Your browser does not support the <code>video</code> element.
        </video>
      </div>
    </div>
  )
}

export default JFKSpeech
