import React from "react"
import PropTypes from "prop-types"
import { useInView } from 'react-intersection-observer'
import IconStar from "../images/icon-star.inline.svg"
import IconGlobe from "../images/icon-globe.inline.svg"
import Teaser from "./teaser"

import "./_how-it-works.scss"

const HowItWorks = ({ teaser }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '-80px 0px',
  })

  return (
    <div className="how-it-works">
      <div className={`header ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
        <h3>How it&nbsp;<em>works</em></h3>
        <div className="icons">
          <IconStar alt="star" />
          <IconGlobe alt="globe" />
        </div>
      </div>
      <div className="teasers">
        {teaser.map((teaser, i) => <Teaser {...teaser} i={i} key={teaser.id} />)}
      </div>
    </div>
  )
}

HowItWorks.propTypes = {
  teaser: PropTypes.array.isRequired
}

export default HowItWorks
