import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { useInView } from 'react-intersection-observer'
import CircleIcon from "../images/icon-circle-arrow.inline.svg"
import IconGlobeAlt from "../images/icon-globe-alt.inline.svg"
import "./_explainer.scss"

const EXPQ1 = ({ quote1 }) => {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '-80px 0px',
  })
  return (
    <div className={`quote1 ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      {quote1}
    </div>
  )
}
const EXPQ2 = ({ quote2 }) => {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '-80px 0px',
  })
  return (
    <div className={`quote2 ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      {quote2}
    </div>
  )
}

const Explainer = ({ title, copy, quote1, quote2 }) => {
  return (
    <div id="explainer">
      {/* <div className="quote1">{quote1}</div> */}
      <EXPQ1 quote1={quote1} />
      <div className="col-wrap">
        <div className="col1">
          <IconGlobeAlt />
          <h3><ReactMarkdown children={title} /></h3>
        </div>
        <div className="col2-3">
          <ReactMarkdown children={copy.data.copy} className="copy"/>
          <a href="/act" className="cta">
            Learn More
            <CircleIcon />
          </a>
        </div>
      </div>
      {/* <div className="quote2">{quote2}</div> */}
      <EXPQ2 quote2={quote2} />
    </div>
  )
}

Explainer.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  quote1: PropTypes.string.isRequired,
  quote2: PropTypes.string.isRequired,
}

export default Explainer
