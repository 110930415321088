import React, { useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer'
import SoundwaveNumber from "./soundwave-number"
import jfkSpeechSrc from "../jfk-speech-safari-riot-remix.mp3"
import "./_jfk-speech.scss"

const JFKSpeech = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const toggleSpeech = () => {
    isPlaying ? audioRef.current.pause() : audioRef.current.play()
    setIsPlaying(!isPlaying)
  }
  const playButton = <StaticImage src="../images/icon-play.png" alt="Play" objectFit="contain" placeholder={"none"} />
  const pauseButton = <StaticImage src="../images/icon-pause.png" alt="Pause" objectFit="contain" placeholder={"none"} />

  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '-80px 0px',
  })

  return (
    <div id="jfk-speech">
      <div className={`jfk-speech-copy ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
        <h2><em>Change sounds good</em></h2>
        <p>We’ve remixed JFK’s words for a new generation. Play the JFK We Choose 2 Remix and keep scrolling to see how this sound will amplify your call for change.</p>
      </div>
      <div className="jfk-speech-audio">
        <audio ref={audioRef}>
          <source src={jfkSpeechSrc} type="audio/mpeg" />
          <track default kind="captions" srcLang="en" />
          Your browser does not support the <code>audio</code> element.
        </audio>
        <SoundwaveNumber i={0} />
        <button onClick={toggleSpeech}>
          { isPlaying ? pauseButton : playButton }
          <div>{isPlaying ? 'Pause' : 'Play'} Remix</div>
        </button>
      </div>
    </div>
  )
}

export default JFKSpeech
